const readingTypes = [
  "toma_tension",
  "toma_temperatura",
  "toma_peso",
  "toma_azucar"
];

const readingConcept = type => {
  switch (type) {
    case "toma_tension":
      return "Blood pressure";
    case "toma_temperatura":
      return "Temperature";
    case "toma_peso":
      return "Weight";
    case "toma_azucar":
      return "Sugar in blood";
  }
  return false;
}

const readingName = type => {
  switch (type) {
    case "toma_tension":
      return "Blood pressure reading";
    case "toma_temperatura":
      return "Temperature reading";
    case "toma_peso":
      return "Weight reading";
    case "toma_azucar":
      return "Sugar in blood reading";
  }
  return false;
}

const readingTitle = type => {
  switch (type) {
    case "toma_azucar":
      return "Read Sugar in blood";
    case "toma_peso":
      return "Read Weight";
    case "toma_temperatura":
      return "Read Temperature";
    case "toma_tension":
      return "Read Blood Pressure";
  }
  return false;
};

const readingAppend = type => {
  switch (type) {
    case "toma_peso":
      return "Kg";
    case "toma_temperatura":
      return "ºC";
    case "toma_azucar":
      return "mg/dl";
    case "toma_tension":
      return "mmHg";
  }
  return false;
};

export { readingTypes, readingConcept, readingName, readingTitle, readingAppend }
