<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="closeModal">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>
        {{ $t("Choose Dependant") }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ul class="dependants">
      <li
        v-for="dependant in dependants"
        :key="dependant.id"
        @click.prevent="setActiveDependant(dependant)"
        :class="{
          active: activeDependant && activeDependant.id == dependant.id
        }"
      >
        <ion-avatar>
          <img :src="dependant.avatar" />
        </ion-avatar>
        <ion-label>{{ dependant.nombre }} {{ dependant.apellidos }}</ion-label>
      </li>
    </ul>
  </ion-content>
</template>
<script>
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonAvatar,
  IonLabel,
  IonButtons,
  IonButton,
  IonIcon
} from "@ionic/vue";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "DependantPicker",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonAvatar,
    IonLabel,
    IonButtons,
    IonButton,
    IonIcon
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const closeModal = () => emit("close");
    let closingModal = false;
    const setActiveDependant = dependant => {
      store.dispatch("auth/setActiveDependant", dependant);
      clearTimeout(closingModal);
      closingModal = setTimeout(closeModal, 700);
    };
    const activeDependant = computed(() => store.state.auth.activeDependant);
    const dependants = computed(() => store.state.auth.user.beneficiarios);
    return {
      setActiveDependant,
      activeDependant,
      closeModal,
      dependants
    };
  }
};
</script>

<style scoped>
ion-content {
  --background: var(--ion-color-light);
}
ion-avatar {
  --border-radius: 50%;
}
.dependants {
  list-style: none;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0;
  padding: 0.5rem 0.5rem;
}
.dependants li {
  display: block;
  width: calc(50% - 1rem);
  margin: 0.5rem;
  background: white;
  border: solid 2px white;
  text-align: center;
  padding: 1.5rem 0.25rem;
  border-radius: 3px;
  max-width: 210px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.dependants li.active {
  border-color: var(--ion-color-primary);
}
.dependants li ion-avatar {
  margin: 0 auto 1rem;
  width: 75px;
  height: 75px;
}
.dependants li ion-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

/* ¿FIX PARA LA CABECERA? ¿QUÉ LE PASA? */
ion-header ion-buttons[slot="start"] + ion-title {
  padding-left: 55px;
}
</style>
