<template>
  <div class="banner-active-dependant ion-padding">
    <div class="inner max-width-mobile">
      <h3 class="title">
        {{ whoIsForText }}
      </h3>
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col class="avatar"
            ><ion-avatar class="avatar-container">
              <ion-img
                class="avatar"
                :src="activeDependant.avatar"
              ></ion-img> </ion-avatar
          ></ion-col>
          <ion-col size="8"
            ><h4 class="dependant-name">
              {{ `${activeDependant.nombre} ${activeDependant.apellidos}` }}
            </h4>
            <a
              v-if="mode == 'create'"
              href="#"
              @click.prevent="showDependantPicker(true)"
              class="change-dependant-link"
              >{{ $t("Change dependant") }}</a
            ></ion-col
          >
          <ion-col
            class="ion-justify-content-end"
            v-if="typeItem.split('_')[0] != 'toma'"
          >
            <ion-img
              :src="`/assets/calendar/${typeItem}.svg`"
              class="ico-img"
              v-if="typeItem"
            ></ion-img
          ></ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
  <ion-modal
    :is-open="showingDependantPicker"
    @onDidDismiss="showDependantPicker(false)"
  >
    <dependant-picker @close="showDependantPicker(false)"></dependant-picker>
  </ion-modal>
</template>

<script>
import {
  IonAvatar,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonModal
} from "@ionic/vue";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import DependantPicker from "@/components/DependantPicker.vue";
import { useI18n } from "vue-i18n";

export default {
  name: "DependantBanner",
  components: {
    IonAvatar,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    DependantPicker,
    IonModal
  },
  props: {
    typeItem: {
      type: String,
      required: true,
      validator: function(value) {
        return (
          [
            "tarea",
            "recordatorio",
            "nota",
            "toma_tension",
            "toma_temperatura",
            "toma_peso",
            "toma_azucar"
          ].indexOf(value) !== -1
        );
      }
    },
    mode: {
      type: String,
      default: "create"
    }
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const activeDependant = computed(() => store.state.auth.activeDependant);
    const showingDependantPicker = ref(false);
    const showDependantPicker = value => (showingDependantPicker.value = value);
    const whoIsForText = computed(() => {
      if (props.mode == "edit") {
        switch (props.typeItem) {
          case "nota":
            return t("Whose Note is this?");

          case "recordatorio":
            return t("Whose Reminder is this?");

          case "toma_peso":
          case "toma_temperatura":
          case "toma_tension":
          case "toma_azucar":
            return t("Who is this reading for?");

          case "tarea":
          default:
            return t("Whose Task is this?");
        }
      }

      switch (props.typeItem) {
        case "nota":
          return t("Who is this Note for?");

        case "recordatorio":
          return t("Who is this Reminder for?");

        case "toma_peso":
        case "toma_temperatura":
        case "toma_tension":
        case "toma_azucar":
          return t("Who is this reading for?");

        case "tarea":
        default:
          return t("Who is this Task for?");
      }
    });

    return {
      activeDependant,
      showDependantPicker,
      showingDependantPicker,
      whoIsForText
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/custom-variables";

.banner-active-dependant {
  border-bottom: 10px solid #f4f5f8;

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    // line-height: 19px;
    line-height: 1.1875;
    margin: 0;
  }

  .avatar {
    max-width: 50px;
  }
  ion-avatar {
    max-width: 46px;
    width: 100%;
    height: auto;
  }

  .dependant-name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    // line-height: 21px;
    line-height: 1.5;
    color: #92949c;
    margin: 0;
  }

  .change-dependant-link {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    // line-height: 14px;
    line-height: 1.1666;
    text-decoration: none;
  }

  .ico-img {
    max-width: 46px;
    width: 100%;
    height: auto;
    margin-left: auto;
  }
}
</style>
