<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="closeModal">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title v-if="mode == 'create'">
        {{ $t("Create {type}", { type: typeName }) }}
      </ion-title>
      <ion-title v-if="mode == 'edit'">
        {{ $t("Edit {type}", { type: typeName }) }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <dependant-banner
      :typeItem="typeItem"
      :mode="mode"
      v-if="isCaregiver"
    ></dependant-banner>
    <form
      @submit.prevent="submit"
      class="max-width-mobile task-form ion-padding"
    >
      <div v-if="isReading">
        <ion-item lines="inset">
          <ion-icon
            :src="`assets/calendar/vitals/${readingType}.svg`"
            slot="start"
          ></ion-icon>
          <ion-label position="floating">{{ $t("Reading") }}</ion-label>
          <div class="inputWithAppend">
            <ion-input
              v-model="task.nombre"
              name="nombre"
              type="number"
              min="0"
              :step="
                readingType == 'tension' || readingType == 'azucar' ? 1 : 0.1
              "
              inputmode="numeric"
              @ionChange="v$.nombre.$touch()"
            >
            </ion-input>
            <div class="inputAppend">
              {{ readingAppend(typeItem) }}
            </div>
          </div>
        </ion-item>
      </div>
      <div v-else-if="typeItem !== 'nota'">
        <ion-item lines="inset">
          <ion-icon :icon="informationOutline" slot="start"></ion-icon>
          <ion-label position="floating">{{ $t("Title") }}</ion-label>
          <ion-input
            v-model="task.nombre"
            name="nombre"
            type="text"
            inputmode="text"
            @ionChange="v$.nombre.$touch()"
          ></ion-input>
        </ion-item>
        <template v-if="v$.nombre.$error">
          <p v-for="(error, i) of v$.nombre.$errors" :key="i" class="error">
            {{ $t(error.$message) }}
          </p>
        </template>
      </div>

      <div>
        <ion-item lines="inset">
          <ion-icon :icon="todayOutline" slot="start"></ion-icon>
          <ion-label position="floating">{{ $t("Day") }}</ion-label>
          <ion-datetime
            v-model="task.fecha"
            name="fecha"
            display-format="DDDD D de MMMM de YYYY"
            :dayNames="customDayNames"
            :monthNames="customMounthNames"
            :cancel-text="$t('Cancel')"
            :done-text="$t('Done')"
            @ionChange="v$.fecha.$touch()"
          ></ion-datetime>
        </ion-item>
        <template v-if="v$.fecha.$error">
          <p v-for="(error, i) of v$.fecha.$errors" :key="i" class="error">
            {{ $t(error.$message) }}
          </p>
        </template>
      </div>

      <div v-if="typeItem !== 'nota'">
        <ion-item lines="inset">
          <ion-icon :icon="timeOutline" slot="start"></ion-icon>
          <ion-label position="floating">{{ $t("Time") }}</ion-label>
          <ion-datetime
            v-model="task.hora"
            name="hora"
            display-format="HH:mm"
            :cancel-text="$t('Cancel')"
            :done-text="$t('Done')"
            @ionChange="v$.hora.$touch()"
          ></ion-datetime>
        </ion-item>
        <template v-if="v$.hora.$error">
          <p v-for="(error, i) of v$.hora.$errors" :key="i" class="error">
            {{ $t(error.$message) }}
          </p>
        </template>
      </div>

      <div>
        <ion-item lines="inset">
          <ion-icon :icon="documentTextOutline" slot="start"></ion-icon>
          <ion-label position="floating" v-if="isReading"
            >{{ $t("Annotation") }} {{ $t("(optional)") }}</ion-label
          >
          <ion-label position="floating" v-else-if="typeItem !== 'nota'"
            >{{ $t("Description") }} {{ $t("(optional)") }}</ion-label
          >
          <ion-label position="floating" v-else
            >{{ $t("Description") }}
          </ion-label>
          <ion-textarea
            v-model="task.descripcion"
            name="descripcion"
            inputmode="text"
            rows="6"
            @ionChange="v$.descripcion.$touch()"
          ></ion-textarea>
        </ion-item>
        <template v-if="v$.descripcion.$error">
          <p
            v-for="(error, i) of v$.descripcion.$errors"
            :key="i"
            class="error"
          >
            {{ $t(error.$message) }}
          </p>
        </template>
      </div>

      <ion-button
        type="submit"
        expand="block"
        size="medium"
        class="ion-margin-top-2"
        >{{ $t("Save {type}", { type: typeName }) }}</ion-button
      >
    </form>
  </ion-content>
</template>
<script>
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonItem,
  IonIcon,
  IonLabel,
  IonInput,
  IonTextarea,
  IonDatetime,
  alertController,
  loadingController
} from "@ionic/vue";
import { computed, reactive, readonly, onBeforeMount } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  informationOutline,
  timeOutline,
  documentTextOutline,
  todayOutline
} from "ionicons/icons";
import { useI18n } from "vue-i18n";
import { DateTime } from "luxon";
import config from "@/config";
import DependantBanner from "@/components/DependantBanner.vue";
import { useRouter } from "vue-router";

import { readingAppend, readingName } from "@/helpers";

export default {
  name: "CreateTask",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonItem,
    IonIcon,
    IonLabel,
    IonInput,
    IonTextarea,
    IonDatetime,
    DependantBanner
  },
  props: {
    typeItem: {
      type: String,
      required: true,
      validator: function(value) {
        return (
          [
            "tarea",
            "recordatorio",
            "nota",
            "toma_tension",
            "toma_temperatura",
            "toma_peso",
            "toma_azucar"
          ].indexOf(value) !== -1
        );
      }
    },
    mode: {
      type: String,
      default: "create"
    },
    defaultItem: {
      type: Object,
      default: null
    }
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const closeModal = () => emit("close");
    const activeDependant = computed(() => store.state.auth.activeDependant);
    const user = computed(() => store.state.auth.user);
    const defaultDate = computed(() =>
      store.state.calendar.diarySelectedDay.toISO()
    );
    const isCaregiver = computed(
      () => store.state.auth.user.cuidados == "cuidador"
    );
    const readingType = computed(() => {
      const parts = props.typeItem.split("_");
      return parts[0] == "toma" ? parts[1] : false;
    });
    const isReading = computed(() => props.typeItem.split("_")[0] == "toma");

    const defaultTime = computed(() =>
      DateTime.now()
        .set({ seconds: 0 })
        .toFormat("HH:mm:ss")
    );
    const { t } = useI18n();

    // Nota: no sé si va mejor aquí o en onBeforeMount
    // para que funciones en onBeforeMount la variable tienes que ser ref()
    // aquí ahora mismo funciona bien... Lo comentamos cuando tomemos unas cervecicas
    let typeName = "";
    switch (props.typeItem) {
      case "nota":
        typeName = t("Note");
        break;

      case "recordatorio":
        typeName = t("Reminder");
        break;
      case "toma_tension":
      case "toma_temperatura":
      case "toma_peso":
      case "toma_azucar":
        typeName = t(readingName(props.typeItem));
        break;
      case "tarea":
      default:
        typeName = t("Task");
        break;
    }

    const customDayNames = [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
      "Domingo"
    ];

    const customMounthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];

    let task = reactive({
      nombre: null,
      descripcion: null,
      fecha: defaultDate.value,
      hora: defaultTime.value,
      tipo: props.typeItem,
      created_by: user.value.id
    });

    const rules = reactive({
      nombre: { required },
      descripcion: {},
      fecha: { required },
      hora: { required },
      tipo: {},
      created_by: {}
    });

    onBeforeMount(() => {
      if (props.mode == "edit") {
        const date = DateTime.fromSQL(props.defaultItem.fecha_desde).startOf(
          "day"
        );
        const time = DateTime.fromSQL(props.defaultItem.fecha_desde).set({
          seconds: 0
        });

        task.id = props.defaultItem.id;
        task.nombre = props.defaultItem.nombre;
        task.descripcion = props.defaultItem.descripcion;
        task.fecha = date.toISO();
        task.hora = time.toFormat("HH:mm:ss");
        task.tipo = props.defaultItem.tipo;
      }

      if (props.typeItem == "nota") {
        task.hora = "00:00:00";
        rules.hora = {};
        rules.nombre = {};
        rules.descripcion = { required };
      }
    });

    const v$ = useVuelidate(rules, task);

    const submit = async () => {
      if (v$._value.$invalid) {
        v$._value.$touch();
        const alert = await alertController.create({
          cssClass: "error-alert",
          header: t("Error"),
          message: t("Please check the data required"),
          buttons: [t("Ok")]
        });
        await alert.present();
        return false;
      }

      const data = {
        ...readonly(task),
        fecha_desde: `${DateTime.fromISO(task.fecha).toFormat("yyyy-MM-dd")} ${
          task.hora
        }`
      };
      const sending = await loadingController.create({
        message: t("Sending...")
      });
      await sending.present();

      let route = "calendar/createItem";
      if (props.mode == "edit") {
        route = "calendar/editItem";
      }
      await store.dispatch(route, data);

      if (store.state.calendar.error) {
        sending.dismiss();
        const alert = await alertController.create({
          cssClass: "error-alert",
          header: t("Error"),
          message: store.state.calendar.error.join("<br>"),
          buttons: [t("Ok")]
        });
        await alert.present();
        return false;
      }

      if (router.currentRoute.value.href.substring(0, 9) == "/calendar") {
        await store.dispatch("calendar/changeCalendarSelectedDay", data.fecha);
      } else {
        await store.dispatch("calendar/changeDiarySelectedDay", data.fecha);
      }

      sending.dismiss();

      if (props.mode == "edit") {
        await router.replace({
          name: router.currentRoute.value.name,
          params: {
            date: data.fecha.substring(0, 10),
            id: task.id
          }
        });
      }
      closeModal();
    };

    return {
      closeModal,
      task,
      v$,
      informationOutline,
      timeOutline,
      documentTextOutline,
      todayOutline,
      submit,
      config,
      customDayNames,
      customMounthNames,
      activeDependant,
      typeName,
      isCaregiver,
      isReading,
      readingType,
      readingAppend
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/custom-variables";
// ion-content {
//   --background: var(--ion-color-light);
// }

/* ¿FIX PARA LA CABECERA? ¿QUÉ LE PASA? */
ion-header ion-buttons[slot="start"] + ion-title {
  padding-left: 48px;
}

form.task-form {
  .item-textarea {
    &.label-floating.sc-ion-label-md-h,
    .label-floating.sc-ion-label-md-h {
      transform: translateY(96%);
    }

    &.item-has-focus.label-floating.sc-ion-label-md-h,
    &.item-has-focus .label-floating.sc-ion-label-md-h,
    &.item-has-placeholder.sc-ion-label-md-h:not(.item-input).label-floating,
    &.item-has-placeholder:not(.item-input) .label-floating.sc-ion-label-md-h,
    &.item-has-value.label-floating.sc-ion-label-md-h,
    &.item-has-value .label-floating.sc-ion-label-md-h {
      transform: translateY(50%) scale(0.75);
    }
  }

  ion-item {
    // --padding-start: var(--inner-padding-end);
    font-size: 14px;

    ion-icon {
      color: $color-black;
      font-size: 18px;
      margin-top: 14px;

      &[slot="start"] {
        margin-inline-end: 25px;
      }
    }
  }

  .error {
    padding-left: 43px;
  }

  // ion-button {
  //   text-transform: lowercase;

  //   &::first-letter {
  //     text-transform: uppercase;
  //   }
  // }
}
html.ios form.task-form {
  ion-item {
    ion-icon {
      margin-top: 35px;
    }
  }
}

.inputWithAppend {
  display: flex;
  justify-content: stretch;
  width: 100%;
}
.inputWithAppend ion-input {
  flex-grow: 1;
  flex-stretch: 0;
}
.inputWithAppend .inputAppend {
  flex-grow: 0;
  color: var(--ion-color-primary);
  white-space: nowrap;
  padding: 0.5rem 0 0.5rem 0.5rem;
  transition: opacity 0.5s ease-out;
  opacity: 0;
}
.inputWithAppend ion-input.has-focus + .inputAppend,
.inputWithAppend ion-input.has-value + .inputAppend {
  opacity: 1;
}

/* ¿FIX PARA LA CABECERA? ¿QUÉ LE PASA? */
ion-header ion-buttons[slot="start"] + ion-title {
  padding-left: 55px;
}
</style>
